<template>
	<div class="content" :class="{ mobile: isMobile }">
		<div class="item-row featured-creators" v-if="FeaturedCreators.length>0">
			<span class="title">{{$t("homeFeaturedCreatorsTitle")}}
				 <img :src="rightIcon" @click="toFeatureList"/>
			</span>
			<div class="group">
				<div class="item" v-for="(item,index) in FeaturedCreators" :key="index">
					<Avatar :size="71" :did="item.did" :hash="item.profilePhoto"  imgType="bigv" :grade="item.grade" showAvatarInfo />
					 <span class="txt mt-lg-2">{{item.userName ? item.userName : item.did.substr(0,5)+"..."+item.did.substr(-5) | substr10}}</span>
				</div>
			</div>
		</div>
		<div class="item-row best-sellers" v-if="BestSellers.length>0">
			<span class="title">{{$t("BestSellers")}}</span>
			<span class="subtitle">{{$t("Last30Days")}}</span>
			<div class="group">
				<div class="item" v-for="(item,index) in BestSellers" :key="index">
					<span class="idx">{{index+1}}</span>
					<Avatar :size="71" :did="item.did" :hash="item.profilePhoto" imgType="bigv" :grade="item.grade" showAvatarInfo />
					 <span class="txt mt-lg-2">{{item.userName ? item.userName : item.did.substr(0,5)+"..."+item.did.substr(-5) | substr10}}</span>
				</div>
			</div>
		</div>
		<div class="item-row productive-creators" style="margin-bottom:120px!important" v-if="ProductiveCreators.length>0">
			<span class="title">{{$t("MostCreators")}}</span>
			<span class="subtitle">{{$t("Last30Days")}}</span>
			<div class="group">
				<div class="item" v-for="(item,index) in ProductiveCreators" :key="index">
					<span class="idx">{{index+1}}</span>
					<Avatar class="avatar" :size="71" :did="item.did" :hash="item.profilePhoto"  imgType="bigv" :grade="item.grade" showAvatarInfo />
					 <span class="txt mt-lg-2">{{item.userName ? item.userName : item.did.substr(0,5)+"..."+item.did.substr(-5) | substr10}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Avatar from "@/components/avatar/index.vue";
	import api from "@/api";
	import rightIcon from "@/assets/icons/icon_r.png";
	
	export default {
		components: {
			Avatar
		},
		data() {
			return {
				rightIcon,
				FeaturedCreators: [],
				BestSellers: [],
				ProductiveCreators: [],
				showBox: false
			}
		},
        computed: {
            isMobile: function () {
                return this.$vuetify.breakpoint.mobile;
            }
		},
		 filters: {
            substr10: function(value) {
                if (value && value.length > 10) {
                    return value.substr(0, 10);
                }
                return value;
            }
        },
		async mounted() {
			let res = await api.home.creatorList({
				chainType: this.$store.state.chainType,
			});
			this.FeaturedCreators = res.data.recommendList.splice(0,10);
			this.BestSellers = res.data.hotList.splice(0,10);
			this.ProductiveCreators = res.data.mostWorkList.splice(0,10);
			  if(this.FeaturedCreators.userNamelength > 12) {
		  		this.username = this.creator.userName.substr(0,12);
	  }
		},
		methods:{
			toFeatureList(){
				this.$router.push({name:'Featurelist'})
				
			}
		}
	}
</script>

<style lang="scss">
	.content {
		// display: flex;
		// flex-direction: column;
		// position: relative;
	}
	.item-row {
		// width: 223px;
		// height: 21px;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		.title {
			font-family:Helvetica;
			font-size: 25px;
			font-weight: normal;
			font-stretch: normal;
			// line-height: 120px;
			letter-spacing: 0px;
			color: #270645;
			margin-top: 30px;

			img {
				display: inline-block;
				width: 41px;
				height: 41px;
				position: relative;
				left: 270px;
    			top: 15px;
				// margin-top: 40px;
			}
		}
		.subtitle {
			font-size: 15px;
		}
		.group {
			display: flex;
			flex-wrap: wrap;
			width: 760px;
			// justify-content: center;
			margin-top: 20px;
			margin-left: 5px;
			.item {
				display: flex;
				flex-direction: column;
				// justify-content: center;
				align-items: center;
				margin: 20px 10px;
				width: 130px;
				height: 131px;
				background-image: linear-gradient(
					#ffffff, 
					#ffffff), 
				linear-gradient(
					#6f58d9, 
					#6f58d9);
				background-blend-mode: normal, 
					normal;
				box-shadow: 0px 0px 9px 0px 
					rgba(0, 0, 0, 0.09);
				border-radius: 5px;
				padding-top: 20px;
				
				.idx {
					width: 10px;
					height: 20px;
					font-size: 25px;
					font-style: italic;
					font-stretch: normal;
					// line-height: 100px;
					letter-spacing: 0px;
					margin-bottom: 30px;
				}
				
				.txt {
				
					font-size: 15px;
					letter-spacing: 0px;
					color: #270645;
					width:90px;
					height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow:ellipsis;
					text-align:center;
					
				}
			}
		}
	}
	
	.best-sellers .item {
		height: 171px !important;
		padding-top: 10px !important;
		.idx {
			color: #9846ff;
		}
	}
	.productive-creators .item {
		height: 171px !important;
		padding-top: 10px !important;
		.idx {
			color: #00ecbd;
		}
	}
	
    .mobile {
        .title {
            width: 355px;
        font-family:Helvetica;
            font-size: 40px !important;
            font-weight: normal;
            font-stretch: normal;
            color: #270645;
            text-align: center;
            line-height: 50px;
        }
        .subtitle{
            font-family:Helvetica;
            font-size: 20px;
            color: #270645;
        }
        .item-row {
            .group {
                width: 100%;
                .item {
                    width: 43%;
                    height: 135px;
                }
            }
        }
    }
</style>
